<template>
    <ViewTitle title="人力查詢" />
    <ManpowerFilter
        :isLoading="isTableLoading"
        @show-data-table="showDataTable"
        v-model:filterPayloadProp="filterPayload"
        v-model:filterLogProp="filterLog"
    />
    <MobileAlertModal v-model="isShowMobileAlert" />
    <ManpowerTable
        :listData="listData"
        :isLoading="isTableLoading"
        :filterLog="filterLog"
        :licenseList="licenseList"
        :stylerList="stylerList"
        :salesTargetList="salesTargetList"
    />
</template>
<script>
import ViewTitle from '@/components/ViewTitle.vue'
import ManpowerFilter from '@/containers/manpower/ManpowerFilter.vue'
import ManpowerTable from '@/containers/manpower/ManpowerTable.vue'
import { getManpowerListAPI } from '@/assets/javascripts/api'
import { lazyLoadComponent } from '@/assets/javascripts/util'
const MobileAlertModal = lazyLoadComponent('containers/report/MobileAlertModal')

export default {
    name: 'Manpower',
    components: {
        ViewTitle,
        ManpowerFilter,
        ManpowerTable,
        MobileAlertModal
    },
    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            this.isTableLoading = true
            this.$showLoading()

            try {
                const response = await this.$getResponse(
                    this.filterPayload,
                    {
                        apiAlias: 'list',
                        apiName: '此報表'
                    },
                    getManpowerListAPI
                )
                this.listData = response.data.member
                this.apiDataProcess(response.data)
            } catch (e) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        apiDataProcess: function (data) {
            this.licenseList = data.licence
            this.stylerList = data.styler
            this.salesTargetList = data.salesTarget
        }
    },
    data() {
        return {
            isShowMobileAlert: false,
            isTableLoading: false,
            dataDate: null,
            listData: [],
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                relationMode: '',
                tLevelName: '',
                baseDate: ''
            },
            filterLog: {},
            licenseList: {},
            stylerList: {},
            salesTargetList: {}
        }
    },
    async created() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>
<style lang="scss" scoped></style>
